import ZorqueF from "../assets/fonts/zorque.woff";
import Zorque2 from "../assets/fonts/zorque.woff2";

const zorque = {
  fontFamily: "zorqueregular",
  src: ` url(${Zorque2}) format('woff2'),
         url(${ZorqueF}) format('woff')`,
  fontWeight: "normal",
  fontStyle: "normal",
};

const fonts = [zorque];

export default fonts;
