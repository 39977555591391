import * as React from "react";

import { Menu, MenuItem, Button, styled, SvgIcon } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./WhitePaperDropDown.scss";
import { ReactComponent as ArrowDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as TokenIcon } from "../../assets/icons/token.svg";
import { ReactComponent as PaperIcon } from "../../assets/icons/paper.svg";

const StyledMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color: "white",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#000000aa",
    },
    "& .MuiMenuItem-root": {
      fontSize: 18,
      textAlign: "center",
      padding: "1rem",
      "&:active": {
        opacity: 1,
      },
      "&:hover": {
        backgroundColor: "#a9a9a9aa",
      },
    },
  },
}));

export default function WhitePaperDropDown() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const urls = {
    docs: "https://docs.pegasusfinance.xyz/",
  };

  const openURL = url => {
    window.open(urls[url], "_self");
    handleClose();
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={() => openURL("docs")}
        disableElevation
        className="linksButton"
      >
        Docs
      </Button>
    </div>
  );
}
