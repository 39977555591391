import { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, Zoom, Divider } from "@mui/material";
import "./home.scss";
import PegaPal1 from "../../assets/pegapal1.png";
import PegaPal2 from "../../assets/pegapal2.png";
import TopBar from "../../components/TopBar/TopBar";
/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
function Home({ yourLocalBalance, readContracts }) {
  const [mobileView, setmobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900 ? setmobileView(true) : setmobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <div className="scaffold">
      <div className="backgroundDesign">
        <TopBar />
        <Grid
          container
          className="mainBox"
          direction="column"
          spacing={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={4}>
            <div className="card-header">
              <Typography className="heading" variant="h5">
                Pegasus
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card-header">
              <Typography className="subTitle" variant="body2">
                Interest rate Perpeptuals
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} className="pegaverseButtons">
            <Button href="https://trade.pegasusfinance.xyz/" className="bootstrapButton" variant="outlined">
              Trade!
            </Button>
          </Grid>
        </Grid>
        {!mobileView && <img src={PegaPal1} className="pegapal pegapalOne" />}
        {!mobileView && <img src={PegaPal2} className="pegapal pegapalTwo" />}
      </div>
    </div>
  );
}

export default Home;
