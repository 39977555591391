import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import "./PegaPals.scss";
import PegaPal from "../../assets/pegapal3.png";
import TopBar from "../../components/TopBar/TopBar";

import Asteroid from "../../assets/PegaCopy/asteroid.png";
import Earth from "../../assets/PegaCopy/earth.png";
import Moon from "../../assets/PegaCopy/moon.png";
import Sun from "../../assets/PegaCopy/sun.png";
import PegaToken from "../../assets/pega_border.png";
import GamePlan from "../../assets/plan.png";

function PegaPals({ yourLocalBalance, readContracts }) {
  const [state, setState] = useState({
    mobileView: false,
  });
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const { mobileView } = state;

  return (
    <div className="scaffold">
      <div className="backgroundPega">
        <TopBar logo={true} />
        <Grid container direction="column" spacing={4} alignItems="center" className="baseScreen">
          <Grid item xs={4} style={{ paddingTop: 15 }}>
            <Typography className="inversePal" variant="h5">
              PegaPals
            </Typography>
            <Typography className="subtitleInverse" variant="h5">
              Your membership into peganation
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img src={PegaPal} className="pegapalKing" />
          </Grid>
          <img src={Asteroid} className={`copyPlanets ${!mobileView && "asteroid"}`} />
          <img src={Moon} className={`copyPlanets ${!mobileView && "moon"}`} />
          <img src={Earth} className={`copyPlanets ${!mobileView && "earth"}`} />
          <img src={Sun} className={`copyPlanets ${!mobileView && "sun"}`} />
        </Grid>
        <Grid container direction="row" spacing={5} alignItems="center" className="box2" justifyContent="space-between">
          <Grid
            container
            direction="column"
            spacing={5}
            alignItems="flex-start"
            className="innerBox"
            justifyContent="space-between"
          >
            <Grid item xs={4} style={{ paddingTop: 15 }}>
              <Typography className="subheading2" variant="h5">
                WTF are Pegapals?
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="info" variant="h5">
                Pegapals are unique 1 of 1 Nfts that will serve as identity for members within the pegasus community.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="info" variant="h5">
                Pegapals have a level attribute which reflects a user's contribution to the protocol and is used to
                calculate staking earnings
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="info" variant="h5">
                Pegapals can be leveled using Pega Tokens, the native currency of Pegasus. Pega tokens can be earned via
                staking, trading, or completing tasks.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="info" variant="h5">
                Higher level Pegapals earn more Staking APY and have more governance weight.
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <img src={PegaToken} className="pegaToken" />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          spacing={5}
          alignItems="flex-start"
          className="box2"
          justifyContent="space-between"
        >
          <Grid item xs={4} style={{ paddingLeft: 0 }}>
            <Typography className="subheading3" variant="h5">
              How do I get one?
            </Typography>
          </Grid>
          <Grid item style={{ paddingLeft: 0 }}>
            <Typography className="info" variant="h5">
              During
              <a
                href="https://pegasusfinance.medium.com/pegapals-part-1-task-contributors-d1bca0a986ff"
                target="_blank"
                className="info"
                style={{ color: "#ff3cbe" }}
                variant="h5"
              >
                {" "}
                The Great Race
              </a>
              , Initial Distribution will be divided into Two Groups.
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className="doubleBoxes"
            justifyContent="space-between"
          >
            <Grid item className="infoBoxParent">
              <Typography className="miniTitle1" variant="h5">
                Auction
              </Typography>
              <div className="infoBox">
                <Typography className="info infosub" variant="h5">
                  - 2000 total auctioned off
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - 1 Auction every 24 hours
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - Earnings sent to the Pegasus treasury
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - Stakeable Immedietly
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - Early minters help decide later tasks
                </Typography>
              </div>
            </Grid>
            <Grid item className="infoBoxParent">
              <Typography className="miniTitle2" variant="h5">
                The Great Race
              </Typography>
              <div className="infoSubBox">
                <Typography className="info infosub" variant="h5">
                  - 1000 Mintable via Sealed Pega Tokens
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - Sealed Pega token are untransferable
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - Sealed Pega Tokens are earned via completing daily tasks
                </Typography>
                <Typography className="info infosub" variant="h5">
                  - First Come First serve minting
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={4} alignItems="flex-start" className="box2">
          <Grid item style={{ paddingLeft: 0 }}>
            <Typography className="stagesText" variant="h5">
              Phases
            </Typography>
          </Grid>
          <Grid item className="stageInfo">
            <Typography className="miniTitle2" variant="h5">
              Phase 1: {<span style={{ color: "#FF3CBE" }}>Blast Off</span>}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="info" variant="h5">
              Phase one will focus on the Initial distribution of pegapals and growing treasury holdings.{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="info" variant="h5">
              Initial participants will be able to join via either financial or effort contribution.
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="info" variant="h5">
              Effort Contributors will take part in a ~30 day race where they must complete a series of tasks each day
              to rapidly spread awareness of the pegasus protocol. Contribtors will be rewarded daily via $Sealed Pega
              Tokens. $Sealed Pega Tokens are the only way outside of auctions to mint pegapals. {"\n"} The tokens are
              untransferable in order to prevent individuals from colluding and sinking tokens into one account.
              Pegapals will be mintable as soon as one has enough tokens, so those who contribute most consistently
              during this period will be able to mint first. This system seeks to allow people who lack the financial
              resources to still enter and enrich the pegasus ecosystem.
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="info" variant="h5">
              the auctions, meanwhile, allow those to enter peganation directly via financial contribution. Earnings
              from the auction will be used to create a backing for each pegapal and kickstart the pegasus treasury.
              Winners of the auction will also be able to shape tasks of the great race via governance.
            </Typography>
          </Grid>
          <Grid item />
          <Grid item className="stageInfo">
            <Typography className="miniTitle1" variant="h5">
              Phase 2:{" "}
              {
                <a
                  href="https://pegasusfinance.medium.com/introducing-the-interest-rate-perpetual-d0adbe924dc4"
                  target="_blank"
                  style={{ color: "#27C7FF" }}
                >
                  IR Perp launch{" "}
                </a>
              }
            </Typography>
            <img src={GamePlan} className="gameplanImg" />
          </Grid>
          <Grid item />
        </Grid>
      </div>
    </div>
  );
}

export default PegaPals;
