import { AppBar, Box, Toolbar, Typography, Button, SvgIcon } from "@mui/material";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";

import "./TopBar.scss";
import { useState, useEffect } from "react";
import DestinationDropDown from "../DestinationDropDown/DestinationDropDown";
import SocialDrop from "../SocialDrop/SocialDrop";
import WhitePaperDropDown from "../WhitePaperDropDown/WhitePaperDropDown";

function TopBar(props) {
  const { logo } = props;

  const urls = {
    medium: "https://pegasusfinance.medium.com/",
    twitter: "https://twitter.com/0xPegasusFi",
    discord: "https://discord.com/invite/q93c74jkJX",
    ogmint: "https://og.pegasusfinance.xyz",
    rekt: "https://rekt.pegasusfinance.xyz",
    trade: "https://www.trade.pegasusfinance.xyz",
  };
  const openURL = url => {
    window.open(urls[url], "_self");
  };

  const [state, setState] = useState({
    mobileView: false,
  });
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  if (state.mobileView) {
    return (
      <AppBar position="static" className="mobileBar">
        <Toolbar disableGutters className="dapp-topbar dappMobile">
          <WhitePaperDropDown />
          <DestinationDropDown />
          <SocialDrop />
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <AppBar position="static" className="appBar">
      <Toolbar disableGutters className="dapp-topbar">
        {logo && (
          <a href="/home">
            <Typography className="logo" variant="h3">
              Pegasus
            </Typography>
          </a>
        )}{" "}
        {!logo && <a></a>}
        <Box display="flex" className="framingButton">
          <WhitePaperDropDown />
          <DestinationDropDown />
          <SocialDrop />
          {/* <Button className="tradeButton" size="small" onClick={() => openURL("trade")} variant="contained">
            Trade
          </Button> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default TopBar;
