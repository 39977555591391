import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import { NETWORKS } from "./constants";

// contracts
import { Home } from "./views";
import { PegaPals } from "./views";
import { dark as darkTheme } from "./themes/dark.js";
import { ThemeProvider, CssBaseline, useMediaQuery } from "@mui/material";
import "./style.scss";
import useGaTracker from "./hooks/useGATracker";

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.mainnet; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// const web3Modal = Web3ModalSetup();

// 🛰 analytics

function App() {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks

  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  useGaTracker();
  // 🔭 block explorer URL

  let themeMode = darkTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      {/* {isAppLoading && <LoadingSplash />} */}
      <div className={`app ${isSmallerScreen && "tablet"}  ${isSmallScreen && "mobile"} `}>
        <Switch>
          <Route exact path="/home">
            <Home />
          </Route>

          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          <Route path="/stake">
            <PegaPals />
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
