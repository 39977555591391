import * as React from "react";

import { Menu, MenuItem, Button, styled, SvgIcon } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./DestinationDropDown.scss";
import { ReactComponent as ArrowDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as TicketIcon } from "../../assets/icons/ticket.svg";
import { ReactComponent as FrogIcon } from "../../assets/icons/frog.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as AuctionIcon } from "../../assets/icons/auction.svg";
import { ReactComponent as QuixoticIcon } from "../../assets/icons/quixotic.svg";

const StyledMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color: "white",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#000000aa",
    },
    "& .MuiMenuItem-root": {
      fontSize: 18,
      textAlign: "center",
      padding: "1rem",
      "&:active": {
        opacity: 1,
      },
      "&:hover": {
        backgroundColor: "#a9a9a9aa",
      },
    },
  },
}));

export default function DestinationDropDown() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const urls = {
    staking: "https://staking.pegasusfinance.xyz/",
    auction: "https://auctions.pegasusfinance.xyz/",
    race: "https://dashboard.pegasusfinance.xyz/",
    og: "https://og.pegasusfinance.xyz/",
    frog: "https://rekt.pegasusfinance.xyz/",
    quixotic: "https://quixotic.io/collection/pegapal",
  };

  const openURL = url => {
    window.open(urls[url], "_blank");
    handleClose();
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        className="linksButton boolishMenu"
        endIcon={<SvgIcon color="primary" component={ArrowDown} viewBox="0 0 20 20" className="icon" />}
      >
        Enter the Pegaverse
      </Button>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getcontentanchorel={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        className="menuDropDown"
      >
        <MenuItem onClick={() => openURL("auction")}>
          <SvgIcon color="primary" component={AuctionIcon} viewBox="0 0 23 23" className="icon" />
          PegaPal Auctions
        </MenuItem>
        <MenuItem onClick={() => openURL("staking")}>
          <SvgIcon color="primary" component={StakeIcon} viewBox="0 0 20 20" className="icon" />
          Staking
        </MenuItem>
        <MenuItem onClick={() => openURL("race")}>
          <SvgIcon color="primary" component={DashboardIcon} viewBox="0 0 20 20" className="icon" />
          The Great Race
        </MenuItem>
        <MenuItem onClick={() => openURL("frog")}>
          <SvgIcon color="primary" component={FrogIcon} viewBox="0 0 64 64" className="icon" />
          Frog Nation Mint
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
