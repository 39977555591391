import * as React from "react";

import { Menu, MenuItem, Button, styled, SvgIcon } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/medium.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";

const StyledMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color: "white",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#000000aa",
    },
    "& .MuiMenuItem-root": {
      fontSize: 18,
      textAlign: "center",
      padding: "1rem",
      "&:active": {
        opacity: 1,
      },
      "&:hover": {
        backgroundColor: "#a9a9a9aa",
      },
    },
  },
}));

export default function SocialDrop() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const urls = {
    medium: "https://pegasusfinance.medium.com/",
    twitter: "https://twitter.com/0xPegasusFi",
    discord: "https://discord.com/invite/q93c74jkJX",
  };
  const openURL = url => {
    window.open(urls[url], "_blank");
    handleClose();
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        className="linksButton"
        endIcon={<SvgIcon color="primary" component={ArrowDown} viewBox="0 0 20 20" className="icon" />}
      >
        Socials
      </Button>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getcontentanchorel={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        className="menuDropDown"
      >
        <MenuItem onClick={() => openURL("twitter")}>
          <SvgIcon color="primary" component={TwitterIcon} viewBox="0 0 28 28" className="icon" />
          Twitter
        </MenuItem>
        <MenuItem onClick={() => openURL("discord")}>
          <SvgIcon color="primary" component={DiscordIcon} viewBox="0 0 28 28" className="icon" />
          Discord
        </MenuItem>
        <MenuItem onClick={() => openURL("medium")}>
          <SvgIcon color="primary" component={MediumIcon} viewBox="0 0 28 28" className="icon" />
          Medium
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
